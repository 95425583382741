@import '../../styles/utils/mixins';
@import '../../styles/utils/variables';

.page {
  padding: 24px;
  position: relative;
  background-color: #9691eb;

  @include onLaptop {
    padding: 40px 0;
  }
}

.content {
  background-color: #f5f2f5;
  padding-top: 20px;
  padding-bottom: 20px;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;
  text-align: center;

  @include onTablet {
    font-size: 32px;
  }
  @include onLaptop {
    font-size: 40px;
  }
}

.subtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;
  text-align: center;

  @include onTablet {
    font-size: 32px;
  }
  @include onLaptop {
    font-size: 40px;
  }
}

.data {
  padding-top: 24px;
  display: flex;
  flex-direction: column;

  @include onLaptop {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
}

.dataContent {
  @include onLaptop {
    flex-basis: 50%;
  }
}

.item {
  margin-bottom: 20px;
}

.itemTitle {
  text-align: center;
  color: #fff;
  font-size: 30px;
  text-shadow: 0 0 3px #4948c6;
}

.itemData {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itemTextWrapper {
  position: relative;
  width: 300px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 4px;
}

.circle {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #000;
}

.itemText {
  & span {
    font-weight: 600;
  }
}

.itemTextList {
  list-style: none;
  margin-bottom: 4px;

  & li {
    margin-left: 22px;

    &::before {
      content: "-";
    }
  }
}
